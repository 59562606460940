import { Button, Checkbox, Form, Icon, Input } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import GoogleLoginImage from '../image/login/google-login-image'

const FormDivider = styled.span`
  text-align: center;
  display: block;
  font-size: 1.6em;
  line-height: 0.8em;
`

const handleSubmit = (): void => {
  console.log('hi')
}

const LoginBox: FC = () => {
  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        <GoogleLoginImage />
      </Form.Item>
      <Form.Item>
        <FormDivider>or</FormDivider>
      </Form.Item>
      <Form.Item>
        <Input
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Username"
        />
        <Input
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Password"
        />

        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ width: '100%' }}
        >
          Log in
        </Button>
        <Checkbox>Remember me</Checkbox>
        <a className="login-form-forgot" href="" style={{ float: 'right' }}>
          Forgot password
        </a>
      </Form.Item>
    </Form>
  )
}
export default LoginBox
