import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

let hovered = false

const enter = () => {
  hovered = true

  console.log('hovered: ' + hovered)
}

const leave = () => {
  hovered = false
}

const GoogleLoginImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "google/btn_google_signin_dark_normal_web@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      onMouseEnter={enter()}
      onMouseLeave={leave()}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export default GoogleLoginImage
