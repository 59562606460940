import { Icon } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

const MoreAnchor = styled.a`
  color: #ffffffc7;
  margin-right: 0.2em;
`

const DownIcon = styled(Icon)`
  position: relative;
  top: 2px;
  left: 5px;
`

interface Props {
  className?: string
}

const LearnMore: FC<Props> = ({ className = '' }) => {
  // via https://medium.com/@chrisfitkin/how-to-smooth-scroll-links-in-gatsby-3dc445299558
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line
    require('smooth-scroll')('a[href*="#"]')
  }
  return (
    <div className={className}>
      <MoreAnchor href="#more">
        Learn more
        <DownIcon type="down" />
      </MoreAnchor>
    </div>
  )
}
export default LearnMore
