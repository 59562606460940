import { Button, Col, Divider, Row, Typography } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import CatalogMetadataImage from '../components/image/catalog-metadata-image'
import FilesImage from '../components/image/files-image'
import LogoImage from '../components/image/logo-image'
import NotebookImage from '../components/image/notebook-image'
import Layout from '../components/layout'
import LoginBox from '../components/login/LoginBox'
import CatalogButton from '../components/nav/CatalogButton'
import LearnMore from '../components/nav/LearnMore'
import SplashNav from '../components/nav/SplashNav'
import SEO from '../components/seo'

const { Paragraph } = Typography

const ShadowDiv = styled.div`
  box-shadow: 3px 3px 14px 0px rgba(224, 224, 224, 1);
  padding: 5px;
`

const Tagline = styled.div`
  font-size: 1.5em;
  letter-spacing: 1px;

  color: #ffffffc7;

  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 95px;

  padding-right: 120px;
  padding-left: 15px;
`
const More = styled(LearnMore)`
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  margin-top: 60px;

  text-align: center;
`

const Logo = styled(LogoImage)`
  position: absolute;
  top: 5px;
  left: 15px;
`
const LoginContainer = styled.div`
  width: 280px;

  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(5px);

  box-shadow: 3px 3px 14px 0px rgba(255, 255, 255, 0.15);
  padding: 10px;

  border-radius: 5px;

  & .ant-form-item,
  & .ant-checkbox-wrapper {
    color: #fff;
  }

  & .ant-input::placeholder {
    color: rgba(0, 0, 0, 0.75);
  }
`

const SecondaryTagline = styled.h1`
  margin-top: 1em;
  text-align: center;
`

const SplashRow = styled(Row)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`

const BrowseDataButton = styled(CatalogButton)`
  font-size: 1.1em;
  margin-top: 1em;
  width: 280px;
`

const AccessBlurb = styled(Paragraph)`
  font-size: 1.2em;
  text-align: center;
`

const JoinButton = styled(Button)`
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;

  && {
    color: #fff;
  }

  &&:hover {
    color: #fff;
  }
`

const IndexPage: FC = () => (
  <>
    <div
      className="full splash-background"
      style={{ height: 600, width: '100%' }}
    >
      <SplashNav />
      <Logo />
      <SplashRow>
        <Col span={14}>
          <Tagline>
            <p>
              We help research programs make an impact by supporting their data
              from the field, to the lab, to publication and re-use.
            </p>

            <More />
          </Tagline>
        </Col>
        <Col span={10}>
          <LoginContainer>
            <LoginBox />
          </LoginContainer>
          <BrowseDataButton label="Browse public data" />
        </Col>
      </SplashRow>
    </div>
    <Layout showHeader={false}>
      <SEO title="Share, analyze, and preserve your team's scientific data" />
      <SecondaryTagline id="more">Foster reproducible science</SecondaryTagline>
      <Divider />
      <Row>
        <Col span={8}>
          <div style={{ marginTop: 50 }}>
            <h3>Capture your findings</h3>
            <p>
              Share your data and manage permissions in a secure environment.
            </p>
            <p>
              Describe your data with a robust ISO 19115 scientific metadata
              editor.
            </p>
          </div>
        </Col>
        <Col span={16}>
          <ShadowDiv style={{ maxWidth: `600px`, marginBottom: `1.45rem` }}>
            {/* image from: https://researchworkspace.com/project/4680/folder/1645604/2012-2016-summer-surveys */}
            <FilesImage />
          </ShadowDiv>
        </Col>
      </Row>
      <p style={{ marginTop: 50 }} />
      <Row>
        <Col span={16}>
          <ShadowDiv style={{ maxWidth: `600px`, marginBottom: `1.45rem` }}>
            <NotebookImage />
          </ShadowDiv>
        </Col>
        <Col span={8}>
          <div style={{ marginTop: 50, marginLeft: 5 }}>
            <h3>Run analysis</h3>
            <p>
              Run Jupyter notebooks in the cloud to analyze your data and other
              vetted datasets.
            </p>
            <p>
              Create reproducible scripts and share your methods with your team.
            </p>
          </div>
        </Col>
      </Row>
      <p style={{ marginTop: 50 }} />
      <Row>
        <Col span={8}>
          <p style={{ marginTop: 50 }} />
          <h3>Share your work</h3>
          <p>
            Publish research to public data portals to meet funding
            requirements.
          </p>
          <p>
            Generate digital object identifiers (DOIs) and submit data to a data
            repository for long-term preservation.
          </p>
        </Col>
        <Col span={16}>
          <ShadowDiv style={{ maxWidth: `600px`, marginBottom: `1.45rem` }}>
            <CatalogMetadataImage />
          </ShadowDiv>
        </Col>
      </Row>
      <p style={{ marginTop: 50 }} />
      <Divider />
      <SecondaryTagline id="more">Join our wait-list</SecondaryTagline>

      <Row>
        <Col offset={6} span={12}>
          <AccessBlurb>
            The Research Workspace is in limited-access mode at this time.
            We&apos;ve already collected over 28 TB of scientific data while we
            refine the user experience. Please join our waitlist to get
            early-access when we accept more users.
            <br />
            <JoinButton
              type="primary"
              size="large"
              href="https://forms.gle/AgV115VPDjfA4YEK8"
            >
              Join our wait-list
            </JoinButton>
          </AccessBlurb>
        </Col>
      </Row>

      <Divider />
    </Layout>
  </>
)

export default IndexPage
